import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContactsQuery } from '@gen2/api/invite-contacts/hooks';
import { TInviteIndicator } from '@gen2/api/invites/api';
import { useTeamMemberListQuery } from '@gen2/api/team-member/hooks';
import { useTeamListQuery } from '@gen2/api/team/hooks';
import {
  Actions,
  Container,
  Content,
  StyledPopover,
  Tab,
  Title,
} from '@gen2/app/components/base-popup-with-tabs/base-popup-with-tabs.styled';

import { useAuth } from '@gen2/hooks';
import { TContact } from '@gen2/types/contact';
import { SortColumn, SortOrder } from '@gen2/types/search-condition';
import { TTeam } from '@gen2/types/team';
import { TUser, TUserStatus } from '@gen2/types/user';
import { Button } from '@mui/material';
import { ModalCloseButton } from '@nx-fe/components';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';
import { useInviteListingStore } from '../store';
import { StyledChip, StyledTabs } from './filter.styled';
import IndicatorTabPanel from './indicator-tab-panel';
import TabPanel from './tab-panel';

export type FilterSearchCondition = {
  'sort[column]': SortColumn;
  'sort[order]': SortOrder;
  'filter[user]'?: string;
  'filter[full_name]'?: string;
  per_page: number;
  page: number;
};

export type TFilterInviteIndicator = Exclude<
  TInviteIndicator,
  'draft' | 'accepted' | 'archived'
>;

const inviteIndicators: TFilterInviteIndicator[] = [
  'sent',
  'no_progress',
  'progressing',
  'awaiting_review',
  'in_review',
  'complete',
  'due_today',
  'overdue',
];

export const InviteListingFilter = () => {
  const { featureFlags } = useAuth();
  const [tabValue, setTabValue] = useState(1);
  const { filter } = useInviteListingStore();
  const [selectedUsers, setSelectedUsers] = useState<TUser[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<TContact[]>([]);
  const [selectedAssignees, setSelectedAssignees] = useState<TUser[]>([]);
  const [selectedAssignedTeams, setSelectedAssignedTeams] = useState<TTeam[]>(
    [],
  );
  const [selectedInviteIndicators, setSelectedInviteIndicators] = useState<
    TFilterInviteIndicator[]
  >([]);

  // check box for user selection or removal
  const { applyFilter, resetClearFlag } = filter;

  useEffect(() => {
    if (filter.clearFlag) {
      clearAll();
      resetClearFlag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.clearFlag]);

  // sender users search
  const [userQuery, setUserQuery] = useState<string>('');
  const [debouncedUserQuery] = useDebounceValue(userQuery, 500);

  // contact search
  const [contactQuery, setContactQuery] = useState<string>('');
  const [debouncedContactQuery] = useDebounceValue(contactQuery, 500);

  // Assignee users search
  const [assigneeQuery, setAssigneeQuery] = useState<string>('');
  const [debouncedAssigneeQuery] = useDebounceValue(assigneeQuery, 500);

  // Assigned Teams search
  const [assignedTeamQuery, setAssignedTeamQuery] = useState<string>('');
  const [debouncedAssignedTeamQuery] = useDebounceValue(assignedTeamQuery, 500);

  const baseFilterSearchCondition = useMemo(() => {
    return {
      'sort[column]': 'created_at' as SortColumn,
      'sort[order]': 'desc' as SortOrder,
      per_page: 25,
      page: 1,
    };
  }, []);

  const baseUserFilterSearchCondition = useMemo(() => {
    return {
      ...baseFilterSearchCondition,
      'filter[status]': ['active'] as TUserStatus[],
    };
  }, [baseFilterSearchCondition]);

  const { data: users, isLoading: isUserLoading } = useTeamMemberListQuery({
    ...baseUserFilterSearchCondition,
    'filter[user]': debouncedUserQuery,
  });
  const { data: contacts, isLoading: isContactLoading } = useContactsQuery({
    ...baseFilterSearchCondition,
    'filter[search]': debouncedContactQuery,
  });
  const { data: assignees, isLoading: isAssigneeLoading } =
    useTeamMemberListQuery({
      ...baseUserFilterSearchCondition,
      'filter[user]': debouncedAssigneeQuery,
    });
  const { data: teams, isLoading: isAssignedTeamLoading } = useTeamListQuery({
    ...baseFilterSearchCondition,
    'filter[team]': debouncedAssignedTeamQuery,
  });

  const handleApply = () => {
    applyFilter({
      selectedUsers,
      selectedContacts,
      selectedAssignees,
      selectedAssignedTeams,
      selectedInviteIndicators,
    });
    filter.closeFilter();
    setTabValue(1);
  };

  const handleTabChange = (newValue: number) => {
    setTabValue(newValue);
  };

  const handleUserReset = () => {
    setUserQuery('');
  };

  const handleUserSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setUserQuery(event.target.value);
  };

  const handleContactReset = () => {
    setContactQuery('');
  };

  const handleContactSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setContactQuery(event.target.value);
  };

  const handleAssigneeReset = () => {
    setAssigneeQuery('');
  };

  const handleAssigneeSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAssigneeQuery(event.target.value);
  };

  const handleAssignedTeamReset = () => {
    setAssignedTeamQuery('');
  };

  const handleAssignedTeamSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setAssignedTeamQuery(event.target.value);
  };

  const clearAll = () => {
    setSelectedUsers([]);
    setSelectedContacts([]);
    setSelectedAssignees([]);
    setSelectedAssignedTeams([]);
    setSelectedInviteIndicators([]);
    filter.clearFilter();
    filter.closeFilter();
    setTabValue(1);
  };

  useEffect(() => {
    return () => {
      setTabValue(1);
    };
  }, []);

  return (
    <div>
      <StyledPopover
        data-cy="filter-popover"
        id={filter.anchorEl ? 'simple-popover' : undefined}
        open={Boolean(filter.anchorEl)}
        anchorEl={filter.anchorEl}
        onClose={filter.closeFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ModalCloseButton aria-label="close" onClick={filter.closeFilter}>
          <FontAwesomeIcon icon={regular('close')} />
        </ModalCloseButton>
        <Container>
          <Content>
            <StyledTabs>
              <Title>Filter By</Title>
              <Tab active={tabValue === 1} onClick={() => handleTabChange(1)}>
                <span> Point of Contact</span>
                {selectedUsers.length > 0 && (
                  <StyledChip
                    color="navy"
                    icon={
                      <FontAwesomeIcon
                        icon={solid('times-circle')}
                        transform="up-1 right-2"
                      />
                    }
                    label={`${selectedUsers.length}`}
                    onClick={() => setSelectedUsers([])}
                  />
                )}
              </Tab>
              <Tab active={tabValue === 2} onClick={() => handleTabChange(2)}>
                <span> Contact</span>
                {selectedContacts.length > 0 && (
                  <StyledChip
                    color="navy"
                    icon={
                      <FontAwesomeIcon
                        icon={solid('times-circle')}
                        transform="up-1 right-2"
                      />
                    }
                    label={`${selectedContacts.length}`}
                    onClick={() => setSelectedContacts([])}
                  />
                )}
              </Tab>
              {featureFlags.manage_invites_part_2 && (
                <>
                  <Tab
                    active={tabValue === 3}
                    onClick={() => handleTabChange(3)}
                  >
                    <span>Assignee</span>
                    {selectedAssignees.length > 0 && (
                      <StyledChip
                        color="navy"
                        icon={
                          <FontAwesomeIcon
                            icon={solid('times-circle')}
                            transform="up-1 right-2"
                          />
                        }
                        label={`${selectedAssignees.length}`}
                        onClick={() => setSelectedAssignees([])}
                      />
                    )}
                  </Tab>
                  <Tab
                    active={tabValue === 4}
                    onClick={() => handleTabChange(4)}
                  >
                    <span>Assigned Team</span>
                    {selectedAssignedTeams.length > 0 && (
                      <StyledChip
                        color="navy"
                        icon={
                          <FontAwesomeIcon
                            icon={solid('times-circle')}
                            transform="up-1 right-2"
                          />
                        }
                        label={`${selectedAssignedTeams.length}`}
                        onClick={() => setSelectedAssignedTeams([])}
                      />
                    )}
                  </Tab>
                  <Tab
                    active={tabValue === 5}
                    onClick={() => handleTabChange(5)}
                  >
                    <span>Invite Status</span>
                    {selectedInviteIndicators.length > 0 && (
                      <StyledChip
                        color="navy"
                        icon={
                          <FontAwesomeIcon
                            icon={solid('times-circle')}
                            transform="up-1 right-2"
                          />
                        }
                        label={`${selectedInviteIndicators.length}`}
                        onClick={() => setSelectedInviteIndicators([])}
                      />
                    )}
                  </Tab>
                </>
              )}
            </StyledTabs>
            {tabValue === 1 && (
              <TabPanel
                data={users?.data}
                isLoading={isUserLoading}
                selectedItems={selectedUsers}
                setSelectedItems={setSelectedUsers}
                handleSearch={handleUserSearch}
                searchQuery={userQuery}
                handleReset={handleUserReset}
                placeholder="Search member by name or email"
                emptyMessage="No results for a Sender with this name or email."
                title="Point of Contact"
              />
            )}
            {tabValue === 2 && (
              <TabPanel
                data={contacts?.data?.contacts}
                isLoading={isContactLoading}
                selectedItems={selectedContacts}
                setSelectedItems={setSelectedContacts}
                handleSearch={handleContactSearch}
                searchQuery={contactQuery}
                handleReset={handleContactReset}
                placeholder="Search contact by name or email"
                emptyMessage="No results for a Contact with this name or email."
                title="Contact"
              />
            )}
            {tabValue === 3 && (
              <TabPanel
                data={assignees?.data}
                isLoading={isAssigneeLoading}
                selectedItems={selectedAssignees}
                setSelectedItems={setSelectedAssignees}
                handleSearch={handleAssigneeSearch}
                searchQuery={assigneeQuery}
                handleReset={handleAssigneeReset}
                placeholder="Search member by name or email"
                emptyMessage="No results for an Assignee with this name or email."
                title="Assignee"
              />
            )}
            {tabValue === 4 && (
              <TabPanel
                data={teams?.data}
                isLoading={isAssignedTeamLoading}
                selectedItems={selectedAssignedTeams}
                setSelectedItems={setSelectedAssignedTeams}
                handleSearch={handleAssignedTeamSearch}
                searchQuery={assignedTeamQuery}
                handleReset={handleAssignedTeamReset}
                placeholder="Search member by name"
                emptyMessage="No results for an Assigned Team with this name."
                title="Assigned Team"
                subTitle="Search by name"
                withAvatar={false}
              />
            )}
            {tabValue === 5 && (
              <IndicatorTabPanel
                data={inviteIndicators}
                selectedItems={selectedInviteIndicators}
                setSelectedItems={setSelectedInviteIndicators}
                title="Invite status"
                subTitle="View invites which have a certain status"
              />
            )}
          </Content>
          <Actions>
            <Button
              variant="contained"
              color="secondary"
              data-cy="clear-all"
              disabled={
                selectedUsers.length <= 0 &&
                selectedContacts.length <= 0 &&
                selectedAssignees.length <= 0 &&
                selectedAssignedTeams.length <= 0 &&
                selectedInviteIndicators.length <= 0
              }
              onClick={clearAll}
            >
              Clear All
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-cy="apply"
              disabled={
                selectedUsers.length <= 0 &&
                selectedContacts.length <= 0 &&
                selectedAssignees.length <= 0 &&
                selectedAssignedTeams.length <= 0 &&
                selectedInviteIndicators.length <= 0
              }
              onClick={handleApply}
            >
              Apply
            </Button>
          </Actions>
        </Container>
      </StyledPopover>
    </div>
  );
};
