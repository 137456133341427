import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { TInviteRequestsForm } from '@gen2/app/invites/send-invites/requests/requests';
import { useRequestsStore } from '@gen2/app/invites/send-invites/requests/store';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { useAuth } from '@gen2/hooks';
import {
  AccordionProps,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { isUndefined } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useConfirmationModalStore } from '../confirmation-modal/hooks/confirmation-modal-store';
import { TRequestContainer, types, useRequestHook } from './hooks/request';
import {
  StyledErrorBox,
  StyledIconButton,
  StyledInviteRequest,
  StyledInviteRequestBody,
  StyledInviteRequestHead,
  StyledTitle,
  StyledType,
  StyledTypesContainer,
} from './invite-request.styles';

export type InviteRequestProps = {
  hasDelete?: boolean;
  isDeleteDisabled?: boolean;
  id: number; // index
  container?: TRequestContainer;
} & Omit<AccordionProps, 'children' | 'id'>;

export const InviteRequest = React.memo(
  ({
    hasDelete,
    isDeleteDisabled,
    id,
    container = 'invite',
  }: InviteRequestProps) => {
    const {
      formState: { errors },
      getValues,
      register,
    } = useFormContext<TInviteRequestsForm>();

    const req = getValues().requests[id];

    const { setToDeleteRequest, activeRequest, setActiveRequest } =
      useRequestsStore();
    const { onUpdate, onChangeVariant } = useRequestHook({ id, container });
    const { setIsConfirmationModalOpen } = useConfirmationModalStore();
    const { showModal } = useActionModalStore();
    const siStore = useSendInviteStore();
    const { featureFlags } = useAuth();

    const { t } = useTranslation('sendInvite');
    const stopPropagation = useMemo(() => {
      return (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        e.stopPropagation();
      };
    }, []);

    const handleDelete = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();

        setToDeleteRequest(req);
        setIsConfirmationModalOpen(true);
      },
      [req, setToDeleteRequest, setIsConfirmationModalOpen],
    );

    const onSelectType = useCallback(
      async (event: SelectChangeEvent) => {
        event.stopPropagation();

        const handleUpdate = (isConfirmed: boolean) => {
          if (!isConfirmed) return;

          onChangeVariant(event.target.value);
        };

        if (siStore.isEditSendInvite) {
          const { isConfirmed } = await showModal({
            header: t('editSendInvite.changeType.title'),
            message: 'editSendInvite.changeType.message',
            translationNamespace: 'sendInvite',
            closeButtonLabel: t('editSendInvite.changeType.cancel') ?? '',
            submitButtonLabel: t('editSendInvite.changeType.confirm') ?? '',
          });

          if (!isUndefined(isConfirmed)) {
            handleUpdate(isConfirmed);
          }
        } else {
          handleUpdate(true);
        }
      },
      [onChangeVariant, showModal, siStore.isEditSendInvite, t],
    );

    const onToggleRequest = useCallback(() => {
      if (activeRequest?.id === req.id) {
        setActiveRequest(null);
      } else {
        setActiveRequest(req);
      }
    }, [activeRequest?.id, req, setActiveRequest]);

    const Variant = useMemo(() => {
      const CurrentVariant = types.find((t) => t.value === req.type)?.component;

      if (!CurrentVariant) return null;

      return <CurrentVariant id={id} container={container} />;
    }, [id, req.type, container]);

    const requestTypes = useMemo(() => {
      if (!featureFlags.read_only_requests) {
        return types.filter((t) => t.value !== 'read-only');
      }

      return types;
    }, [types]);

    return (
      <>
        <StyledInviteRequest
          $hasError={!!(errors?.requests && errors.requests[id])}
          square
          disableGutters
          expanded={activeRequest?.id === req?.id}
          onChange={onToggleRequest}
          data-cy="invite-request"
        >
          <StyledInviteRequestHead
            expandIcon={
              <FontAwesomeIcon icon={regular('chevron-right')} size="sm" />
            }
          >
            <StyledTitle
              fullWidth
              variant="filled"
              size="small"
              onClick={stopPropagation}
              {...register(`requests.${id}.title`, {
                onBlur: onUpdate,
              })}
              data-cy={`request-title-${id}`}
              inputProps={{
                'data-cy': 'request-title-input',
              }}
            />
            <StyledTypesContainer onClick={stopPropagation}>
              <FormControl fullWidth color="info" size="small">
                <InputLabel id="request-type">Request Type</InputLabel>
                <Select
                  data-cy="request-type-menu"
                  inputProps={{
                    'data-cy': 'request-type',
                  }}
                  fullWidth
                  label="Request Type"
                  value={req.type}
                  {...register(`requests.${id}.type`)}
                  onChange={onSelectType}
                >
                  {requestTypes.map((t) => (
                    <StyledType key={t.value} value={t.value}>
                      {t.label}
                    </StyledType>
                  ))}
                </Select>
              </FormControl>
            </StyledTypesContainer>
            {hasDelete && (
              <Tooltip title="Delete" arrow placement="top">
                <StyledIconButton
                  onClick={handleDelete}
                  disabled={isDeleteDisabled}
                  color="tonalLight"
                >
                  <FontAwesomeIcon icon={regular('trash-can')} size="sm" />
                </StyledIconButton>
              </Tooltip>
            )}
          </StyledInviteRequestHead>

          <StyledInviteRequestBody>
            {activeRequest?.id === req?.id && Variant}
          </StyledInviteRequestBody>
        </StyledInviteRequest>
        {!!errors.requests && errors.requests[id]?.title && (
          <StyledErrorBox data-cy="error">
            {t(errors.requests[id]?.title?.message || '')}
          </StyledErrorBox>
        )}
      </>
    );
  },
);
