import SearchBar from '@components/lib/searchbar/searchbar';
import { colors, weights } from '@components/theme/gen2';
import LoadingButton from '@mui/lab/LoadingButton';
import { DialogContent, IconButton, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { rem } from 'polished';

export const TemplatesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(20),
  padding: rem(20),

  [theme.breakpoints.up('md')]: {
    gap: rem(30),
    paddingLeft: rem(60),
    paddingRight: rem(60),
  },
}));

export const TemplatesHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: rem(20),

  [theme.breakpoints.down('md')]: {
    '& > div[role="searchBox"]': {
      maxWidth: rem(340),
    },
  },

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: rem(20),

    '& > div[role="searchBox"]': {
      maxWidth: rem(480),
    },

    '& > *:last-child': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
}));

export const TemplatesHeaderRight = styled('div')({
  display: 'flex',
  button: {
    flexShrink: 0,
  },
});

export const TemplatesHeaderActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: rem(10),
  marginTop: rem(20),
  marginBottom: rem(10),

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: rem(15),
    marginTop: rem(10),
    marginBottom: 0,
  },
}));

export const TemplatesSearchBar = styled(SearchBar)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '100%',
    maxWidth: rem(480),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const TemplatesHeaderTitle = styled('h3')(({ theme }) => ({
  fontSize: rem(20),
  fontWeight: weights.semiBold,
  margin: 0,
  lineHeight: rem(20),
  marginBottom: rem(15),

  [theme.breakpoints.up('md')]: {
    marginBottom: 0,
  },
}));

export const TemplateCards = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
  gap: rem(15),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: rem(20),
  },
}));

export const TemplateCard = styled('div')<{
  $active?: boolean;
  displayOnly?: boolean;
}>(({ $active, theme, displayOnly }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  aspectRatio: '1 / 1',
  backgroundColor: colors.grayLight2,
  borderRadius: rem(10),
  overflow: 'hidden',
  border: displayOnly ? 'none' : `${rem(2)} solid ${colors.grayLight2}`,
  cursor: displayOnly ? 'default' : 'pointer',

  ...($active && {
    border: `${rem(2)} solid ${colors.primary}`,
  }),

  ...(!displayOnly && {
    [theme.breakpoints.up('md')]: {
      '&:hover': {
        border: `${rem(2)} solid ${colors.secondary}`,
      },
    },
  }),
}));

export const TemplateCardHeader = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: rem(6),
});

export const TemplateCardMoreOptionsButton = styled(IconButton)({
  position: 'absolute',
  width: rem(30),
  height: rem(30),
  top: 0,
  right: 0,

  '> svg': {
    fontSize: rem(16),
  },
});

export const TemplateMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  gap: rem(10),
});

export const TemplateCardContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 'auto',
  marginBottom: 'auto',
  zIndex: 3,
});

export const TemplateCardDate = styled('p')({
  height: rem(20),
  borderRadius: rem(10),
  paddingLeft: rem(8),
  paddingRight: rem(8),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.white,
  fontSize: rem(11),
  fontWeight: weights.medium,
});

export const TemplateCardName = styled('p')({
  marginTop: rem(10),
  textAlign: 'center',
  paddingLeft: rem(20),
  paddingRight: rem(20),
  fontWeight: weights.semiBold,
  marginBottom: rem(20),
});

export const TemplateCardBadge = styled('div')({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: rem(50),
  height: rem(50),
  borderTop: `${rem(25)} solid ${colors.grayLight2}`,
  borderLeft: `${rem(25)} solid ${colors.grayLight2}`,
  borderRight: `${rem(25)} solid ${colors.primary}`,
  borderBottom: `${rem(25)} solid ${colors.primary}`,

  span: {
    color: colors.white,
    fontSize: rem(12),
    fontWeight: weights.medium,
    position: 'absolute',
    transform: 'translateX(-15%) rotate(-45deg)',
    textTransform: 'uppercase',
  },
});

export const EmptyTemplates = styled('div')({
  fontSize: rem(14),
  fontWeight: weights.medium,
  color: colors.grayDark1,
});

export const TemplateContent = styled(DialogContent)<{ $loading?: boolean }>(
  ({ $loading }) => ({
    minHeight: rem(500),

    ...($loading && {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  }),
);

export const TemplatePaginationArrows = styled(IconButton)<{
  $right?: boolean;
}>(({ $right }) => ({
  position: 'absolute',
  top: '50%',
  left: rem(10),
  transform: 'translateY(-50%)',
  zIndex: 5,
  backgroundColor: colors.white,
  width: rem(40),
  height: rem(40),
  border: `${rem(1)} solid ${colors.gray}`,
  fontSize: rem(16),

  '&:hover': {
    backgroundColor: colors.grayLight2,
  },

  ...($right && {
    left: 'auto',
    right: rem(10),
  }),
}));

export const TemplateLoadingCard = styled('div')({
  aspectRatio: '1 / 1',
  width: '100%',
  display: 'flex',
  borderRadius: rem(10),
  overflow: 'hidden',
  position: 'relative',

  '> svg': {
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    opacity: 0.4,
    fontSize: rem(40),
  },
});

export const TemplateLoadMoreButton = styled(LoadingButton)(({ theme }) => ({
  gridColumn: '1 / -1',
  width: '100%',
  maxWidth: rem(400),
  marginLeft: 'auto',
  marginRight: 'auto',

  [theme.breakpoints.up('md')]: {
    marginTop: rem(10),
  },
}));
